<template>
  <router-view></router-view>
</template>

<script>
import Vue from "vue";
import VueCurrencyFilter from "vue-currency-filter";


Vue.use(VueCurrencyFilter, {
  symbol: "Rp",
  thousandsSeparator: ".",
  fractionCount: 0,
  fractionSeparator: ",",
  symbolPosition: "front",
  symbolSpacing: true,
});
export default {
  name: "App",
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
</style>
